.bg-setter {
  background: #f8f8f8;
}

.swiper-container {
  width: 100%;
  /*height: 100%;*/
  //margin: 2rem auto;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #ededed;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.quick-entry {

  width: 100%;
  background: #ebebeb;
  a {
    padding: 5px 10px;
    display: inline-block;
    color: cornflowerblue;
  }
}



.section-title {
  color: #666;
  text-align: left;
  span {
    color: #666;font-size: 16px; padding: 5px;
  }
}


.width-ctrl {
  margin: 0 auto;

  @media screen and (min-width:1280px) {
    width: 1260px;
  }
  @media screen and (max-width:1279px) {
    width: 100%;
  }
}



.course-item {
  display: inline-block;
  background: #fff;
  float: left;
  &:hover {
    box-shadow: 0px 3px 10px #d5d5d5;
    .img-block img{
      transform: scale(1.2);
      transition: 0.2s;
    }
  }
  @media screen and (min-width:1280px) {
    width: 19%;
    margin: 0.5%;
  }
  @media screen and (max-width:1279px) {
    width: 48%;
    margin: 1%;
  }
  @media screen and (max-width:767px) {
    width: 96%;
    margin: 2%;
  }
}

.img-block {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 135px;
  border:1px solid #edeff0;
  border-bottom:none;
  img {
    display: block;
    width: 100%;
  }
  .features {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(90, 90, 90, 0.36);
    color: floralwhite;
    padding: 3px 7px;
  }
  i.fa {
    color: white;
    transform: translate(0,-50%);
    margin-top: 25%;
    font-size: 3em;
  }
}

.info-block {
  text-align: left;
  padding: 5px;
  background: #fff;
  border:1px solid #edeff0;
  color: #666;
  .title {
    font-size: 16px;
    margin: 5px 0;
  }
}


.price-mode-0{
  color: salmon;
}


.course-BlcuItic {
  display: inline-block;
  background: #fff;
  float: left;
  &:hover {
    box-shadow: 0px 3px 10px #d5d5d5;
    .img-block img{
      transform: scale(1.2);
      transition: 0.2s;
    }
  }
  @media screen and (min-width:1280px) {
    width: 24%;
    margin: 0.5%;
  }
  @media screen and (max-width:1279px) {
    width: 48%;
    margin: 1%;
  }
  @media screen and (max-width:767px) {
    width: 96%;
    margin: 2%;
  }
}
.course-competition {
  display: inline-block;
  background: #fff;
  &:hover {
    box-shadow: 0px 3px 10px #d5d5d5;
    .img-block img {
      transform: scale(1.2);
      transition: 0.2s;
    }
  }
}